<template>
  <div class="chat-log" :class="{
    role: role,
    'waiting': status === 'waiting',
    'excluded': !includedAsContext,
    'user_message_text': role === 'user',
    'assistant_message_text': role === 'assistant',
    'login_prompt_text': role === 'login-prompt',
    'buy_credit_prompt_text': role === 'buy-credit-prompt',
    'web_search_result': role === 'web-search',
  }">

    <div>
      <Toggle v-if="role === 'user' || role === 'assistant' || role === 'web-search'" class="include_toggle_switch"
        v-model="includedAsContext" @click="checkboxClicked" />
      <button class="delete-btn" @click="deleteItem">X</button>

    </div>

    <div v-if="role === 'login-prompt'" class="chat-log-content">
      Please <a class="link-primary" @click="emitLogInEvent">login with Google</a> to continue.
    </div>
    <div v-else-if="role === 'buy-credit-prompt'" class="chat-log-content">
      Please buy more credit to continue. Remember to input your login email used here. <a class="link-primary"
        target="_blank" href="https://buy.stripe.com/cN25ls2A3duLabK9AC">Purchse link</a>.
    </div>
    <div v-else class="chat-log-content" v-html="formattedMessage"></div>
  </div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<style src="highlightjs-copy/dist/highlightjs-copy.min.css"></style>
<style scoped>
.chat-log {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px 0px;
  border-radius: 5px;
  word-wrap: break-word;
}

.chat-log-content {
  text-align: left;
  margin: 0px;
  padding: 5px 10px;
}

.excluded {
  opacity: 0.5;
}

.delete-btn {
  border-radius: 15px;
  color: red;
  margin: 0px 10px;
  text-align: right;
  width: 50px;
  height: 25px;
  border: none;
}

.user_message_text {
  background-color: #FDFDFD;
  color: #444b53;
}

.assistant_message_text {
  background-color: #444653;
  color: #FDFDFD;
}

.web_search_result {
  color: rgb(252, 255, 50);
  font-weight: bold;
  background-color: #808080;
}

.login_prompt_text,
.buy_credit_prompt_text {
  background-color: #eedcdc;
  color: #de570a;
  opacity: 1.0;
}

.include_toggle_switch {
  display: flex;
  padding: 10px;
}

::v-deep .chat-log-content code {
  background-color: #000000;
  padding: 2px 4px;
  border-radius: 5px;
}

::v-deep .chat-log-content pre {
  background-color: #000000;
  padding: 10px;
  border-radius: 5px;
}

/* .waiting:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0px;
} */

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}
</style>

<script>
import CopyButtonPlugin from 'highlightjs-copy';
import Toggle from '@vueform/toggle';
import hljs from "highlight.js";
import 'highlight.js/styles/arduino-light.css';
import { EventBus } from '@/utils/EventBus';
import { formatAsMarkdown } from '@/utils/Util';

hljs.addPlugin(new CopyButtonPlugin({
  callback: (text) => console.log("Copied to clipboard", text),
}));

export default {
  name: "ChatLogItem",

  props: {
    message: String,
    role: String,
    status: String,
    pos: Number,
    includedAsContextInit: Boolean,
  },

  data() {
    return {
      includedAsContext: this.includedAsContextInit,
    }
  },

  components: {
    Toggle,
  },

  computed: {
    formattedMessage() {
      const formatted = formatAsMarkdown(this.message);
      return formatted;
    },
  },

  methods: {
    checkboxClicked() {
      EventBus.emit("include-in-context-checkbox-clicked");
    },

    emitLogInEvent() {
      EventBus.emit('userWantToLogin');
    },

    deleteItem() {
      EventBus.emit("ChatLogItemDeleted", this.pos);
    },
  },

  watch: {
    includedAsContext(newValue) {
      EventBus.emit("IncludeInContextToggled", {
        pos: this.pos,
        included: newValue,
      });
    },

    status(newValue) {
      if (newValue === "final") {
        hljs.highlightAll();
      }
    },
  },

  updated() {
    this.includedAsContext = this.includedAsContextInit;
  },
};
</script>
