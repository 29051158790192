<template>
  <div class="chat-messages" ref="chatMessages">
    <ChatLogItem v-for="(msg, index) in messages" :key="index" :message="msg.text" :role="msg.role" :status="msg.status"
      :includedAsContextInit="msg.includedInContext" :pos="index" />
  </div>
</template>

<script>

import ChatLogItem from './ChatLogItem.vue';

export default {
  name: 'ChatMessages',
  components: {
    ChatLogItem,
  },
  data() {
    return {

    };
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  methods: {

  },
  mounted() {
    console.log('ChatMessages mounted');
  },
  updated() {
    //console.log('ChatMessages updated');
  },
};
</script>

<style scoped>
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
}
</style>