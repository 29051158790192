<template>
  <div class="top-bar link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
    <h1 class="logo-text">WhatGPT</h1>
    <button v-if="!isUserLoggedIn" type="button" class="login-with-google-btn" @click="loginWithGoogle">
      Sign in with Google
    </button>
    <h3 v-else>
      {{ currentUserEmail }} |
      <a href="#" @click="logout">sign out</a>
    </h3>
  </div>
</template>

<style scoped>
.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
}

.logo-text {
  padding-top: 10px;
  font-weight: 800;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 42px;
  border: 1px solid;
  border-radius: 10px;



  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}
</style>

<script>
import { signInWithPopup, getAuth, onAuthStateChanged, signOut, GoogleAuthProvider } from "firebase/auth";
import { auth, provider } from "@/utils/Auth";
import { EventBus } from "@/utils/EventBus";

export default {

  data() {
    return {
      isUserLoggedIn: false,
      currentUserEmail: ''
    }
  },

  methods: {
    loginWithGoogle() {
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("Google sign in successful");

          // Extract the Google API access token
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;

          // Get the Firebase ID token
          return result.user.getIdToken(true).then((idToken) => {
            // Emit an event with both tokens included
            this.$emit('authUpdated', {
              'googleIdToken': idToken,
            });

            this.$emit('googleDriveTokenRecieved', {
              'googleAPIToken': accessToken
            });

            // Optional: Console log for debugging
            console.log("Google API Access Token:", accessToken);
            console.log("Firebase ID Token for backend:", idToken);
          });

        })
        .catch((error) => {
          console.log("Google sign in error: ", error);
        });
    },

    logout() {
      const firebaseAuth = getAuth();
      signOut(firebaseAuth)
        .then(() => {
          this.isUserLoggedIn = false;
          this.$emit('userLoggedOut');
          console.log('User signed out successfully!');
        })
        .catch((error) => {
          console.error('Error signing out: ', error);
        });
    },
  },

  mounted() {
    const firebaseAuth = getAuth();
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        this.isUserLoggedIn = true;
        this.currentUserEmail = user.email;
        user.getIdToken(true).then((idToken) => {
          console.log('idToken:', idToken);
          console.log('userName:', user.displayName);
          console.log('email:', user.email);
          this.$emit('authUpdated', {
            'googleIdToken': idToken,
          });
        });
      } else {
        this.isUserLoggedIn = false;
      }
    });

    EventBus.on("userWantToLogin", () => {
      this.loginWithGoogle();
    });
  },
}
</script>