<template>
  <div id="app">
    <!-- <ControlPanel /> -->
    <div class="right-section">
      <TopBar @authUpdated="authWithBackend" @googleDriveTokenRecieved="googleDriveTokenRecieved"
        @userLoggedOut="logoutUser" />
      <ChatMessages :messages="messages" />
      <ChatInput @user-prompt="sendPrompt" @DoWebSearch="DoWebSearch" @user-prompt-updated="updateTokenEstimation"
        :balance="balance" :costEstimation="costEstimation"
        :status="status"
        :inputLocked="inputLocked"
         ref="chatInput" />
    </div>
  </div>
</template>

<style>
#app {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  height: 100%;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 2% 0 2%;
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 49em;
  word-wrap: break-word;
  height: 100%;
}
</style>


<script>
import axios from 'axios';
//import ControlPanel from '@/components/ControlPanel.vue';
import ChatMessages from '@/components/ChatMessages.vue';
import ChatInput from '@/components/ChatInput.vue';
import TopBar from './components/TopBar.vue';
import { Message } from '@/models/Message';
import { EventBus } from '@/utils/EventBus';
import { getCloudFunctionUrl, handleHTTPError, fetchStream } from '@/utils/Util';
import { addMessage, syncWithGoogleDrive } from '@/utils/BrowserMessageStore';
import { encoding_for_model as tiktokenModel } from "@dqbd/tiktoken";
import { USD_PER_INPUT_TOKEN, USD_PER_OUTPUT_TOKEN, NUM_TOKEN_RESPONSE_AVG }
  from '@/constants'

export default {
  name: 'App',

  created() {
    this.tokenEncoder = tiktokenModel("gpt-4");
  },

  data() {
    return {
      userInfo: { balance: 0 },
      messages: [],
      creditBalance: 0,
      costEstimation: 0,
      balance: 0.0,
      status: "",
      defaultContextInclude: true,
      googleIdToken: null,  // Used to sign in with Firebase
      inputLocked: false,
    };
  },

  components: {
    //ControlPanel,
    ChatMessages,
    ChatInput,
    TopBar,
  },
  methods: {
    googleDriveTokenRecieved({ googleAPIToken }) {
      console.log('Begin syncing messages with Google Drive');
      syncWithGoogleDrive(googleAPIToken).then(() => {
        console.log('Google Drive sync complete');
      });
    },

    updateTokenEstimation(currentPrompt = "") {
      var numToken = this.tokenEncoder.encode(currentPrompt).length;
      this.messages.forEach((msg) => {
        if (msg.includedInContext === true) {
          let txt = msg.text;
          if (msg.role === "web-search") {
            txt = msg.contextForAI;
          }
          numToken += this.tokenEncoder.encode(txt).length;
        }
      });
      this.costEstimation = numToken * USD_PER_INPUT_TOKEN +
        NUM_TOKEN_RESPONSE_AVG * USD_PER_OUTPUT_TOKEN;
      console.log(`Input token num: ${numToken},` +
        `cost estimation: ${this.costEstimation}`);
    },

    handleFailedPromptResponse(response) {
      if (response.status === 402) {
        console.log('Insufficient balance');
        this.messages[this.messages.length - 1] = new Message('BUY CREDIT  PLACEHOLDER', 'buy-credit-prompt', 'final', false);
      } else if (response.status === 401) {
        console.log('Server says Google ID token is invalid');
        this.messages[this.messages.length - 1] = new Message('LOGIN PROMPT PLACEHOLDER', 'login-prompt', 'final', false);
      } else {
        console.error('Unexpected response from server: ' + response);
      }
    },

    clearHistory() {
      this.messages = [];
    },

    DoWebSearch(prompt) {
      const SEP = "==323990.323jfjkla;jf======aasdfa=======";
      (async () => {
        this.inputLocked = true;
        const stream = fetchStream(
          getCloudFunctionUrl(),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "x-api-key": "Bethsaida",
            },
            body: JSON.stringify({
              action: "google",
              prompt: prompt,
              uid: this.userInfo.uid,
              googleIdToken: this.googleIdToken,
            }),
          },
          () => {
            this.messages.push(new Message('', 'web-search', 'waiting', this.defaultContextInclude));
            this.status = "Doing web search";
          },
          (failedResponse) => {
            this.handleFailedPromptResponse(failedResponse);
          }
        );

        let sepReceived = false;
        let searchResults = "";
        let googleQuery = "";
        for await (const chunk of stream) {
          const textChunk = new TextDecoder("utf-8").decode(chunk);
          console.log(`Received chunk: [${textChunk}]`);
          searchResults += textChunk;

          if (googleQuery === "") {
            let match = searchResults.match(/Google using query: \[(.+?)\]/);
            googleQuery = match ? match[1] : "";
          }

          if (searchResults.includes(SEP)) {
            sepReceived = true;
            this.messages[this.messages.length - 1].text = "Finalizing results";            
            continue;
          }
          if (!sepReceived) {
            this.messages[this.messages.length - 1].text = textChunk;
          } 
        }
        if (sepReceived) {
          return {
            query: googleQuery,
            searchResults: searchResults.substring(searchResults.indexOf(SEP) + SEP.length).trim()
          };
        } else {
          throw new Error("Google search failed to return results");
        }

      })().then(({query, searchResults}) => {
        let msg = this.messages[this.messages.length - 1];
        msg.contextForAI = "Below is the latest results from Web Search. If necessary, use them when as your context in this conversation.\n\n" + searchResults;
        msg.status = 'final';
        const cost = USD_PER_INPUT_TOKEN * this.tokenEncoder.encode(msg.contextForAI).length;
        msg.text = `[$${cost.toFixed(1)}] Web search results for [${query}]`;
        this.messages[this.messages.length - 1] = msg;
        this.updateTokenEstimation(prompt);
        console.log('Web search finished');
      }).catch((error) => {
        console.error('Web search operation error: ' + error);
        let msg = this.messages[this.messages.length - 1];
        msg.text = error.message;
        msg.status = 'final';         
        msg.includedInContext = false;
        this.messages[this.messages.length - 1] = msg;        
      }).finally(() => {
        this.unlockChatInput();
      });
    },

    sendPrompt({ prompt, modelVersion }) {
      let updateTokenEst = () => {
        this.updateTokenEstimation();
        this.authWithBackend({
          googleIdToken: this.googleIdToken
        });
      }
      let history_to_server = this.messages.filter((msg) => {
        return msg.includedInContext === true;
      }).map((msg) => {
        return {
          content: msg.contextForAI !== null ? msg.contextForAI : msg.text,
          role: msg.role !== "web-search" ? msg.role : "assistant",
        };
      });

      let data = {
        action: "userPrompt",
        uid: this.userInfo.uid,
        history: history_to_server,
        prompt: prompt,
        modelVersion: modelVersion,
        googleIdToken: this.googleIdToken,
      };

      console.log(`About to send user prompt: ${prompt}, modelVersion: ${modelVersion}, size: ${history_to_server.length} `
      );

      this.messages.push(new Message(prompt, 'user', 'final',
        this.defaultContextInclude));

      if (this.messages.length === 1) {
        document.title = prompt;
      }

      (async () => {
        this.inputLocked = true;
        const stream = fetchStream(
          getCloudFunctionUrl(),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "x-api-key": "Bethsaida",
            },
            body: JSON.stringify(data),
          },
          () => {
            // We add the placeholder reponse here
            this.messages.push(new Message('', 'assistant', 'waiting', this.defaultContextInclude));
            EventBus.emit('ReceivedServerResponse');
            this.status = "Recieving AI response";
          }
        );
        for await (const chunk of stream) {
          const textChunk = new TextDecoder("utf-8").decode(chunk);
          this.messages[this.messages.length - 1].text += textChunk;
        }
        this.messages[this.messages.length - 1].status = 'final';
        updateTokenEst();
        addMessage(
          prompt,
          this.messages[this.messages.length - 1].text,
          modelVersion
        );
      })().then(() => {
        console.log('AI response Stream closed');
        this.unlockChatInput();
      }).catch((error) => {
        console.error('Stream error: ' + error);
      }).finally(() => {
        this.unlockChatInput();
      });
    },

    unlockChatInput() {
      this.status = ""
      this.inputLocked = false;
    },

    authWithBackend({ googleIdToken }) {
      axios.post(
        getCloudFunctionUrl(),
        {
          action: "authenticate",
          googleIdToken: googleIdToken,
        },
        {
          headers: {
            "x-api-key": "Bethsaida",
          }
        }
      )
        .then((response) => {
          this.userInfo = response.data;
          console.log('User info updated: ' + JSON.stringify(this.userInfo));
          this.googleIdToken = googleIdToken;
          this.balance = this.userInfo.balance;
        })
        .catch(function (error) {
          handleHTTPError(error);
        });
    },


    logoutUser() {
      this.userInfo = { balance: 0 };
      this.googleIdToken = null;
    },

    onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        // The tab has become visible, execute your code here
        console.log('Tab is now active');
        if (this.googleIdToken !== null) {
          console.log("Re-authenticate after window is reactivated");
          this.authWithBackend({
            googleIdToken: this.googleIdToken
          });
        }
      } else {
        console.log('Tab is now inactive');
      }
    },
  },

  mounted() {
    // Triggers when the tab becomes active
    document.addEventListener('visibilitychange', this.onVisibilityChange);

    EventBus.on('ChatLogItemDeleted', (pos) => {
      this.messages.splice(pos, 1);
      this.updateTokenEstimation();
      console.log("Removed deleted messages, current message count: " + this.messages.length);
    });

    EventBus.on('total-context-include-changed', (val) => {
      console.log('total-context-include-changed: ' + val);
      this.messages.forEach((msg) => {
        if (val === 'none') {
          msg.includedInContext = false;
        } else if (val === 'all') {
          msg.includedInContext = true;
        }
      });
      this.defaultContextInclude = val !== 'none';
      this.updateTokenEstimation();
    });

    EventBus.on('clear-history', () => {
      this.clearHistory();
    });

    EventBus.on('IncludeInContextToggled', ({ pos, included }) => {
      console.log(`IncludeInContextToggled for ${pos}, include: ${included}`);
      //debugger;
      if (pos >= 0 && pos < this.messages.length) {
        this.messages[pos].includedInContext = included;
        this.updateTokenEstimation();
      }
    });

    EventBus.on('append-search-match', (selected) => {
      selected.forEach((msg) => {
        this.messages.push(msg);
      });
      this.updateTokenEstimation();
      console.log(`Appended ${selected.length} messages from search`);
    });

    window.WhatGPT = this;
  },
};
</script>