import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBBtnl6OdLVUN_BA9lkaOczf6Xy5EixJ-Q",
  authDomain: "whatgpt-19345.firebaseapp.com",
  projectId: "whatgpt-19345",
  storageBucket: "whatgpt-19345.appspot.com",
  messagingSenderId: "86766503770",
  appId: "1:86766503770:web:151a13c75f402099111563",
  measurementId: "G-FC13DPY4PG"
};

export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const provider = new GoogleAuthProvider();

provider.addScope('https://www.googleapis.com/auth/drive.file');