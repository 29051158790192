<template>
  <Transition>
    <div v-if="showModal">
      <div class="modal-mask" @keydown.esc="closeSearchUI" tabindex="0">
        <div class="modal-wrapper">
          <div class="modal-container">
            <input v-model="searchQuery" @input="query(false)" @keyup.enter="query(true)" class="search-box" type="text"
              placeholder="Search chat history" />

            <div class="modal-body">
              <div class="matched-messages-holder">
                <div v-for="(msg, idx) in matchedMessages" :key=idx class="matched-message">
                  <div v-html="formatMatchedMessage(msg.text, showFullMessage[idx])" class="matched-message-presenter">
                  </div>
                  <div class="matched-message-control">
                    <Toggle v-model="showFullMessage[idx]" class="view-toggle message-toggle" on-label="Full"
                      off-label="Preview" />
                    <Toggle v-model="msg.includedInContext" class="include-toggle message-toggle" on-label="Included"
                      off-label="Add?" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary ok-btn" @click="closeSearchUI">
                OK
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style src="@vueform/toggle/themes/default.css"></style>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: flex;
  margin-top: 10em;
  height: 70%;
  overflow-y: auto;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.search-box {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
  max-height: 95%;
}

.matched-messages-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.matched-message {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: justify;
  padding: 1em 0;
  flex-shrink: calc(100% - 10em);
  flex-wrap: nowrap;
}

.matched-message-presenter {
  padding: 0 1em;
}

.matched-message-control {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.message-toggle {
  margin-left: 1em;
  ;
  margin-bottom: 0.5em;
  --toggle-width: 6em;
  --toggle-font-size: 1rem;
}

.matched-message:not(:last-child) {
  border-bottom: 1px solid #888;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

<style>
.matched-kw {
  color: red;
}
</style>

<script>
import Toggle from '@vueform/toggle';

import { Message } from '@/models/Message';
import { formatAsMarkdown } from '@/utils/Util';
import { EventBus } from '@/utils/EventBus';
import { searchMessages } from '@/utils/BrowserMessageStore';

function extendMatchToLeft(text, endPos) {
  let numPrefixWord = 5;
  let pos = endPos - 1;
  while (numPrefixWord >= 0 && pos >= 0) {
    while (pos >= 0 && text[pos] !== ' ') {
      pos--;
    }
    if (text[pos] === ' ') {
      numPrefixWord--;
      pos--;
    }
  }
  return pos + 1;
}

function extendMatchToRight(text, beginPos) {
  let numSuffixWord = 5;
  let pos = beginPos;
  while (numSuffixWord >= 0 && pos < text.length) {
    while (pos < text.length && text[pos] !== ' ') {
      pos++;
    }
    if (text[pos] === ' ') {
      numSuffixWord--;
      pos++;
    }
  }
  return pos;
}

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: '',
      matchedMessages: [],
      keywords: [],
      showFullMessage: [],
    };
  },

  components: {
    Toggle,
  },

  methods: {
    query(forceSearch) {
      if (this.searchQuery.length === 0) {
        this.matchedMessages = [];
        return;
      }

      if (!forceSearch && this.searchQuery.match(/.+\s$/g) === null) {
        return;
      }

      const kw = this.searchQuery.split(/\s+/).filter(
        (word) => word.length > 0,
      ).map(
        (word) => word.toLowerCase(),
      );

      if (kw.length === 0) {
        return;
      }

      console.log("History query: " + kw);
      this.keywords = kw;


      searchMessages(kw).then((messages) => {
        this.matchedMessages = [];
        // We only find AI's responses
        // this.matchedMessages = messages.map((msg) => new Message(
        //   msg.prompt,
        //   'user',
        //   'final',
        //   false,
        // ));
        this.matchedMessages.push(...messages.map((msg) => new Message(
          msg.response,
          'assistant',
          'final',
          false,
        )));
        this.showFullMessage = this.matchedMessages.map(() => false);
      });
    },

    formatMatchedMessage(text, showFull) {
      if (showFull) {
        return formatAsMarkdown(text);
      } else {
        return this.formatForPreview(text);
      }
    },

    formatForPreview(text) {
      let mark = [];
      this.keywords.forEach((kw) => {
        [...text.matchAll(new RegExp(kw, "gi"))].forEach((match) => {
          let left = extendMatchToLeft(text, match.index);
          let right = extendMatchToRight(text, match.index + kw.length);
          mark.push([left, right]);
        });
      });
      mark.sort((a, b) => a[0] - b[0]);
      mark = mark.reduce((acc, cur) => {
        if (acc.length === 0) {
          acc.push(cur);
        } else {
          let last = acc[acc.length - 1];
          if (cur[0] <= last[1]) {
            last[1] = Math.max(last[1], cur[1]);
          } else {
            acc.push(cur);
          }
        }
        return acc;
      }, []);
      var formatted = [];
      for (let [begin, end] of mark) {
        let piece = text.substring(begin, end);
        new Set(this.keywords).forEach(
          (kw) => piece = piece.replace(new RegExp(kw, "gi"),
            `<span class="matched-kw">${kw}</span>`),
        );
        formatted.push(piece);
      }
      return "... " + formatted.join(" ... ") + " ...";
    },

    closeSearchUI() {
      this.$emit('close-search-modal');
      let selected = this.matchedMessages.filter((msg) => msg.includedInContext);
      EventBus.emit("append-search-match", selected);
    },
  },

  mounted() {

  }
}

</script>