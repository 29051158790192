import { createApp } from 'vue';
import App from './App.vue';

import "highlight.js/styles/a11y-dark.css";
import 'bootstrap/dist/css/bootstrap.min.css';


const app = createApp(App);
app.mount('#app');


window.WhatGPT = app;