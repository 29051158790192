const GOOGLE_DRIVE_FILENAME = 'WhatGPT_Chat_Log_Never_Modify.json';

export async function findGoogleDriveFileID(googleAPIToken) {
  const fileName = GOOGLE_DRIVE_FILENAME;
  const mimeType = 'application/json';

  // Step 1: Search for an existing messages.json file
  let fileId = null;
  const searchResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=name='${fileName}' and trashed=false and mimeType='${mimeType}'`, {
    method: 'GET',
    headers: new Headers({
      'Authorization': `Bearer ${googleAPIToken}`,
      'Content-Type': 'application/json',
    })
  });

  if (searchResponse.ok) {
    const searchResult = await searchResponse.json();
    if (searchResult.files && searchResult.files.length > 0) {
      // Assuming the first match is the file we want
      fileId = searchResult.files[0].id;
      return fileId;
    } else {
      console.log(`No existing ${fileName} file found from Google Drive`);
      return null;
    }
  } else {
    throw new Error(`Error searching messages.json from Google Drive: ${searchResponse.statusText}`);
  }
}

export async function fetchFromGoogleDrive(googleAPIToken) {
  const fileId = await findGoogleDriveFileID(googleAPIToken);

  // Assuming single result for simplicity; you might need to handle multiple or zero results
  if (fileId === null) {
    console.log('Google drive chat log not found.');
    return [];
  }

  // Once the fileId is known, proceed to download its content
  const downloadResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
    headers: new Headers({
      'Authorization': `Bearer ${googleAPIToken}`
    })
  });

  if (!downloadResponse.ok) {
    throw new Error(`Error downloading file: ${downloadResponse.statusText}`);
  }

  const messages = await downloadResponse.json();
  return messages; // Assuming the file content directly contains the JSON array of messages
}

export async function uploadToGoogleDrive(messages, googleAPIToken) {
  let url, method, headers, body;
  const fileContent = JSON.stringify(messages);
  const fileId = await findGoogleDriveFileID(googleAPIToken);
  if (fileId === null) {
    console.log('Creating file in Google Drive');
    method = 'POST';
    url = `https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`;
    const metadata = {
      name: GOOGLE_DRIVE_FILENAME,
      mimeType: 'application/json'
    };
    const boundary = '-------314159265358979323846';
    headers = {
      'Authorization': `Bearer ${googleAPIToken}`,
      'Content-Type': `multipart/related; boundary=${boundary}`
    };
    body = `
--${boundary}
Content-Type: application/json; charset=UTF-8

${JSON.stringify(metadata)}

--${boundary}
Content-Type: application/json

${fileContent}
--${boundary}--
`;
  } else {
    console.log('Overwriting Google Drive data');
    // Updating existing file
    method = 'PATCH';
    url = `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=media`;
    headers = {
      'Authorization': `Bearer ${googleAPIToken}`,
      'Content-Type': 'application/json'
    };
    body = fileContent;
  }

  const response = await fetch(url, {
    method: method,
    headers: new Headers(headers),
    body: body
  });

  if (!response.ok) {
    throw new Error(`Error ${fileId ? 'updating' : 'creating'} file: ${response.statusText}`);
  }

  await response.json();
}

