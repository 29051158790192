import MarkdownIt from "markdown-it";
import hljs from "highlight.js";

export function getCloudFunctionUrl() {
  if (process.env.NODE_ENV === 'production') {
    // The current environment is production
    return "https://us-west1-whatgpt-19345.cloudfunctions.net/gateway"
  } else {
    // The current environment is not production (likely development)
    return "http://localhost:8081/"
  }
}

export function handleHTTPError(error) {
  console.log("HTTP Request to backend failed. Error: ", error);
}

const markdownFormatter = new MarkdownIt({
  html: true,
  linkify: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) {
        return markdownFormatter.utils.escapeHtml(str);
      }
    }

    // try to auto detect language 
    try {
      return hljs.highlightAuto(str).value;
    } catch (e) {
      console.log(`Failed to detech language from the mark down: ${e}`);
    }

    return markdownFormatter.utils.escapeHtml(str);
  },
});

export async function* fetchStream(url, options = {}, responseRecivedCallback = null,
  non200ResponseCallback = null) {
  const response = await fetch(url, options);

  if (!response.body) {
    throw new Error("ReadableStream not supported in this browser.");
  }

  if (!response.ok) {
    if (non200ResponseCallback) {
      non200ResponseCallback(response);
    }
    return;
  }

  if (responseRecivedCallback) {
    responseRecivedCallback();
  }

  const reader = response.body.getReader();

  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}


export function formatAsMarkdown(text) {
  return markdownFormatter.render(text);
}