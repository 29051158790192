import { v4 as uuidv4 } from 'uuid';
import { removeStopwords } from 'stopword';

import { fetchFromGoogleDrive, uploadToGoogleDrive } from './GoogleDriveClient';

class BrowserMessageStore {
  constructor() {
    this.dbName = 'WhagGPTDB';
    this.storeName = 'ChatLog';
    this.dbVersion = 1; // Change this if you need to modify the database schema in the future
    this.db = null;
    this.init();
  }

  // Initialize the IndexedDB
  async init() {
    if (!('indexedDB' in window)) {
      throw new Error('This browser doesn\'t support IndexedDB');
    }

    this.db = await new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onerror = (event) => {
        reject(new Error('IndexedDB initialization failed:', event.target.error));
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName, { keyPath: 'uuid' });
        }

        if (!db.objectStoreNames.contains('keywordIndex')) {
          const keywordStore = db.createObjectStore('keywordIndex', { keyPath: 'keyword' });
          keywordStore.createIndex('uuids', 'uuids', { unique: false, multiEntry: true });
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
  }

  // Add a message to the store
  async addMessage({ timestamp, prompt, response, model }) {
    if (!this.db) {
      await this.init();
    }
    const uuid = uuidv4();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName, 'keywordIndex'], 'readwrite');

      transaction.oncomplete = () => {
        resolve();
      };

      transaction.onerror = (event) => {
        reject(new Error('Transaction failed:', event.target.error));
      };

      const objectStore = transaction.objectStore(this.storeName);
      const keywordStore = transaction.objectStore('keywordIndex');

      objectStore.add({ uuid, timestamp, prompt, response, model });

      // Extract and process keywords
      // For now, we only index the response
      // const combinedText = `${prompt} ${response}`.toLowerCase();
      const combinedText = response.toLowerCase();
      const keywords = new Set(removeStopwords(combinedText.match(/\b(\w+)\b/g)));


      // Update the index for each keyword
      keywords.forEach(keyword => {
        let request = keywordStore.get(keyword);

        request.onsuccess = () => {
          let data = request.result;
          if (data) {
            data.uuids.push(uuid);
          } else {
            data = { keyword, uuids: [uuid] };
          }
          keywordStore.put(data);
        };
      });
    });
  }

  async searchKeyword(keyword) {
    if (!this.db) {
      await this.init();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['keywordIndex'], 'readonly');
      const objectStore = transaction.objectStore('keywordIndex');
      const request = objectStore.get(keyword);

      request.onsuccess = () => {
        if (request.result) {
          console.log(`Found ${request.result.uuids.length} logged messages for keyword: ${keyword}`);
          resolve(request.result.uuids);
        } else {
          console.log(`No chat log found for keyword: ${keyword}`);
          resolve([]);
        }
      };

      request.onerror = (event) => {
        reject(new Error('Keyword search failed:', event.target.error));
      };
    });
  }

  async getMessagesByUUIDs(uuids) {
    if (!this.db) {
      await this.init();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const objectStore = transaction.objectStore(this.storeName);

      let messages = [];

      transaction.oncomplete = () => {
        resolve(messages);
      };

      transaction.onerror = (event) => {
        reject(new Error('Transaction failed:', event.target.error));
      };

      uuids.forEach(uuid => {
        let request = objectStore.get(uuid);

        request.onsuccess = () => {
          if (request.result) {
            messages.push(request.result);
          }
        };
      });
    });
  }


  // Fetch all messages
  async fetchAllMessages() {
    if (!this.db) {
      await this.init();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName]);
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.getAll();

      request.onerror = (event) => {
        reject(new Error('Fetching messages failed:', event.target.error));
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
  }
}

export async function searchMessages(keywords) {
  let uuids = new Set();
  const messageStore = new BrowserMessageStore();
  for (const keyword of keywords) {
    const keywordUUIDs = await messageStore.searchKeyword(keyword);
    keywordUUIDs.forEach(uuid => uuids.add(uuid));
  }
  console.log(`Found ${uuids.size} unique messages for keywords: ${keywords}`);
  return await messageStore.getMessagesByUUIDs(Array.from(uuids));
}


export function addMessage(prompt, response, model) {
  const messageStore = new BrowserMessageStore();
  messageStore.addMessage({
    timestamp: Date.now(),
    prompt: prompt,
    response: response,
    model: model
  })
    .then(() => {
      console.log("Message added to IndexedDB");
    });
}

export async function syncWithGoogleDrive(googleAPIToken) {
  const messageStore = new BrowserMessageStore();
  // Initially fetch all messages stored locally in IndexedDB
  const allLocalMessages = await messageStore.fetchAllMessages();

  // Create a Set to store the UUIDs of all local messages for faster lookups
  const localMessageUUIDs = new Set(allLocalMessages.map(message => message.uuid));

  console.log(`Fetched ${localMessageUUIDs.size} messages from IndexedDB`);
  // Fetch messages from Google Drive
  const googleDriveMessages = await fetchFromGoogleDrive(googleAPIToken);

  console.log(`Fetched ${googleDriveMessages.length} messages from Google Drive`);

  // Iterate through each message fetched from Google Drive
  for (const message of googleDriveMessages) {
    // Check if this message's UUID exists in the Set of local message UUIDs
    if (!localMessageUUIDs.has(message.uuid)) {
      // If message UUID is new, add it to IndexedDB
      await messageStore.addMessage({
        timestamp: message.timestamp,
        prompt: message.prompt,
        response: message.response,
        model: message.model,
      });
    }
  }

  const mergedMessages = await messageStore.fetchAllMessages();
  // Upload the merged messages back to Google Drive
  await uploadToGoogleDrive(mergedMessages, googleAPIToken);
}